import { AxiosError } from "axios";
import { Errors } from "services/errors";

export class AuthErrors extends Errors {
  signIn = (err: AxiosError) => {
    switch (err.response?.status) {
      case 401:
        this.warning({
          title: this.t("alerts.accessDenied"),
          description: this.t("errors.invalidAccess"),
        });
        break;
      default:
        this.defaultError(err);
        break;
    }
  };

  signUp = (err: AxiosError) => {
    switch (err.response?.status) {
      case 400:
        this.warning({
          title: this.t("alerts.registeredEmail"),
          description: this.t("alerts.interruptedAction"),
        });
        break;
      default:
        this.defaultError(err);
        break;
    }
  };

  activateAccount = (err: AxiosError) => {
    switch (err.response?.status) {
      case 422:
        this.warning({ description: this.t("errors.invalidToken") });
        break;
      default:
        this.defaultError(err);
        break;
    }
  };

  newPassword = (err: AxiosError) => {
    switch (err.response?.status) {
      case 422:
        this.warning({
          description: this.t("errors.invalidToken"),
        });
        break;
      default:
        this.defaultError(err);
        break;
    }
  };
}
