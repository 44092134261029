// Models

import { GridColumnType } from "components/grid";
import {
  BaseReponseType,
  ControlTypeEnum,
  OrderModel,
  QuestionModel,
} from "models";

export enum DbTypeEnum {
  AnsiString = 0,
  Binary = 1,
  Byte = 2,
  Boolean = 3,
  Currency = 4,
  Date = 5,
  DateTime = 6,
  Decimal = 7,
  Double = 8,
  Guid = 9,
  Int16 = 10,
  Int32 = 11,
  Int64 = 12,
  Object = 13,
  SByte = 14,
  Single = 15,
  String = 16,
  Time = 17,
  UInt16 = 18,
  UInt32 = 19,
  UInt64 = 20,
  VarNumeric = 21,
  AnsiStringFixedLength = 22,
  StringFixedLength = 23,
  Xml = 25,
  DateTime2 = 26,
  DateTimeOffset = 27,
}

export enum SortByEnum {
  lastMessage = "DT_LAST_MESSAGE_DESC",
  lastAttendannce = "ID_OMNI_DESC",
  oldMessage = "DT_LAST_MESSAGE_ASC",
  oldAttendance = "ID_OMNI_ASC",
}

export enum SubChannelTypeEnum {
  CallMe = 2,
  Chat = 4,
  ChatExternal = 13,
  Email = 9,
  EmailMarketing = 14,
  FacebookFeed = 10,
  FacebookMessenger = 8,
  Inbound = 11,
  ManualCall = 12,
  PersonalSchedule = 3,
  Predictive = 7,
  Preview = 1,
  SMS = 5,
  VirtualAgent = 15,
  WhatsApp = 6,
}

export type AttendanceModel = {
  IdOmni: number;
  NmOmni: string;
  IdSubChannelType: SubChannelTypeEnum;
  DtLastMessage: string;
  DeLastMessage: string;
  DtStart: string;
  NmAttendGroup: string;
  QtNewMessages: number;
  NuPhoneWhatsapp: string;
  IsValid: boolean;
  DeValidate: string;
  IdValidate: number;
  NuDDD: string;
  NuPhone: string;
  NuDDI: string;
  isFromPersonalScheduleApi: boolean;
  HasCallDialerAPI: boolean;
  HasIntegrationApp: boolean;
  DeUrlIntegrationApp: string;
  HasTransfer: boolean;
  HasSchedule: boolean;
  HasTransferAssisted: boolean;
  IsFinishedByMailing: boolean;
  HasValidBoteriaSessionWhatsApp: boolean;
  IsBoteriaWhatsAppIntegrated: boolean;
};

export type AttendanceTicketModel = {
  IdTicket: number;
  NmCustomer: string;
  NuDDD: string;
  NuPhone: string;
  DtRegister: string;
  CodeProtocol: string;
  DtSchedule: string;
  DeObs: string;
  NmStatusProduct1: string;
  NmStatusProduct2: string;
  NmStatusProduct3: string;
  NmCustom1: string;
  NmCustom2: string;
  NmCustom3: string;
  NmCustom4: string;
  NmCustom5: string;
  DeLogin: string;
  NmUser: string;
  NmStatus: string;
  BindListData?: {
    NmControl: string;
    VlProperty: string;
    NmColumn: string;
  }[];
};

export type IntegrationModel = {
  NmData: string;
  VlData: string;
};

export type FormFieldModel = {
  NmColumn: string;
  DeCaption: string;
  DeControlType: ControlTypeEnum;
  VlProperty: string | number;
  MustBeFilled: boolean;
  MustBlockEditData: boolean;
  Col: GridColumnType;
  Mask: string;
  DbType: DbTypeEnum;
  IdDbType: DbTypeEnum;
  NmControl: string;
  MustUseEyeToogle: boolean;
  HasDetail: boolean;
  NmsControlsBindsDetails: string;
  comboList: {
    value: string;
    name: string;
    valueParent?: string;
  }[];
};

export type HistoricModel = {
  IdMailing: string;
  DtTimeline: string;
  IdOmni: string;
  DtStart: string;
  DtFinish: string;
  NmStatus: string;
  IdChannelType: number;
  NmChannelType: string;
  IdSubChannelType: SubChannelTypeEnum;
  NmSubChannelType: string;
  NmUser: string;
  DeSubject: string;
  IdOmniGroup: number;
  IdCall: number;
  DeObs: string;
};

export type SearchMailingInDSModel = {
  IdMailing: number;
  NmMailing: string;
  NuDdd1: string;
  NuPhone1: string;
  NuRegistration: string;
  NmStatus: string;
};

export type SearchMailingModel = SearchMailingInDSModel & {
  DtLastOmni: string;
  NmUserLastOmni: string;
  NmOmniFolderLastOmni: string;
  NmOmniSource: string;
};

export class SearchOmnigExportClass {
  IdOmni: number;
  IdOmniSource: number;
  IdMailing: number;
  NmMailing: string;
  NuRegistration: string;
  IdStatus: number;
  NmStatus: string;
  DtStart: string;
  NmUser: string;
  NmOmniFolder: string;
  NuPhoneWhatsapp: string;
  NuDdd: string;
  NuPhone: string;
  NmSubChannelType: string;
  NmOmniSource: string;

  constructor(s: SearchOmniModel) {
    this.IdOmni = s.IdOmni;
    this.IdOmniSource = s.IdOmniSource;
    this.IdMailing = s.IdMailing;
    this.NmMailing = s.NmMailing;
    this.NuRegistration = s.NuRegistration;
    this.IdStatus = s.IdStatus;
    this.NmStatus = s.NmStatus;
    this.DtStart = s.DtStart;
    this.NmUser = s.NmUser;
    this.NmOmniFolder = s.NmOmniFolder;
    this.NuPhoneWhatsapp = s.NuPhoneWhatsapp;
    this.NuDdd = s.NuDdd1;
    this.NuPhone = s.NuPhone1;
    this.NmSubChannelType = s.NmSubChannelType;
    this.NmOmniSource = s.NmOmniSource;
  }
}

export type SearchOmniModel = {
  IdOmni: number;
  IdMailing: number;
  NmMailing: string;
  NuRegistration: string;
  IdStatus: number;
  NmStatus: string;
  DtStart: string;
  NmUser: string;
  NmOmniFolder: string;
  NuPhoneWhatsapp: string;
  NuDdd1: string;
  NuPhone1: string;
  NmSubChannelType: string;
  IdSubChannelType: SubChannelTypeEnum;
  NmOmniSource: string;
  IdOmniSource: number;
};

export type MessageModel = {
  IdOmniMessage: number;
  DeOmniMessage: string;
  DtDisplay: string;
  IsFromMailing: boolean;
  IsFromBOT: boolean;
};

export type UserQueuesModel = {
  NmAttendGroup: string;
  DtLastUpdate: string;
  QtOmnisActives: number;
  IsPaused: boolean;
  DeInfocomplement: string;
};

// Requests

type BaseParams = {
  pIdOmni: string | number;
};

export type AddAttendanceRequest = {
  pIdProject: number;
  pIdMailing: number;
  pIdOmniSource: number;
};

export type AddAttendanceByDSRequest = {
  pIdProject: number;
  pIdMailingDialerAPI: number;
  pIdOmniSource: number;
  pNuDDD: string;
  pNuPhone: string;
  pNmMailing: string;
};

export type AddAttendanceByOmniRequest = BaseParams & {
  pIdProject: number;
};

export type AnswerQuestionRequest = BaseParams & {
  pIdQuestion: number;
  pIdAnswer?: number;
  pDeValueTyped?: string;
};

export type CancelTransferAssitedRequest = BaseParams;

export type ConfirmConferenceAttendanceRequest = BaseParams;

export type ConfirmTransferAssitedRequest = BaseParams;

export type CreateContactRequest = {
  pIdProject: number;
  pNmMailing: string;
  pNuDdd: string;
  pNuPhone: string;
  pIdOmniSource: number;
  pNuRegistration: string;
};

export type DialAttendanceRequest = BaseParams & {
  pNuDDDMakeCall: string;
  pNuPhoneMakeCall: string;
};

export type DialAttendanceFromOtherMediaRequest = BaseParams & {
  pNuDDDMakeCall: string;
  pNuPhoneMakeCall: string;
};

export type DialConferenceAttendanceRequest = BaseParams & {
  pNuDDD: string;
  pNuPhone: string;
};

export type FinishAttendanceRequest = BaseParams & {
  pIdOmniFolderFinishOmni: number;
  pIdStatusFinishOmni: number;
  pDeObsFinishOmni: string;
};

export type FinishConferenceAttendanceRequest = BaseParams;

export type GetAgentAPIsRequest = {
  pIdProject: number;
};

export type GetAttendancesRequest = {
  pIdProject: number;
};

export type GetIntegrationsRequest = BaseParams;

export type GetFormFieldsRequest = BaseParams;

export type GetFormOrderRequest = BaseParams;

export type GetFormTicketRequest = BaseParams;

export type GetHistoriesRequest = BaseParams;

export type GetMessagesIdRequest = BaseParams & {
  pWasGet: boolean;
};

export type GetMessagesRequest = BaseParams & {
  pIdOmniMessage: string[];
};

export type GetMeQueuesRequest = {
  pIdUser: number;
};

export type GetOrderListRequest = BaseParams;

export type GetQuestionRequest = BaseParams;

export type GetQuizRequest = BaseParams;

export type GetTicketListRequest = BaseParams;

export type GetTokenByRoomRequest = {
  pRoom: string;
};

export type HangupAttendanceRequest = BaseParams;

export type ManageOrderItemRequest = BaseParams & {
  pIdOrder: number;
};

export type ManageTicketItemRequest = BaseParams & {
  pIdTicket: number;
};

export type PreviousQuestionRequest = BaseParams;

export type RedialAttendanceRequest = BaseParams & {
  pIdStatusMakeCallRedial: number;
  pNuDDDMakeCallRedial: string;
  pNuPhoneMakeCallRedial: string;
  pDeObsMakeCallRedial: string;
};

export type ScheduleAttendanceRequest = BaseParams & {
  pIdStatusSchedule: number;
  pDtBestimeDate: string;
  pDtBestimeTime: string;
  pNuDDDSchedule: string;
  pNuPhoneSchedule: string;
  pDeObsSchedule: string;
};

export type SearchAttendanceRequest = {
  pIdProject: number;
  pIdSubChannelType?: number;
  pNmMailing: string;
  pNuDdd: string;
  pNuPhone: string;
  pNuRegistration: string;
  pIdOmniFolder: number;
  pDtStart: string;
  pDtFinish: string;
  pIdUser?: number;
};

export type SearchContactRequest = {
  pIdProject: number;
  pNmMailing: string;
  pNuDdd: string;
  pNuPhone: string;
  pNuRegistration: string;
  pIdOmniFolder?: number;
};

export type SearchContactInDSRequest = SearchContactRequest & {
  pNuContract: string;
};

export type SendFileRequest = BaseParams;

export type SendFilesByTicketRequest = BaseParams & {
  pIdTicket: number;
};

export type SendHSMRequest = BaseParams & {
  pIdMessageOutboundTemplate: number;
};

export type SendHSMFromOtherMediaRequest = BaseParams & {
  pIdMessageOutboundTemplate: number;
};

export type SendMessageRequest = BaseParams & {
  pDeNewOmniMessage: string;
};

export type StartNewRecordRequest = BaseParams;

export type StartNPSSearchRequest = BaseParams;

export type TransferAssistedAttendanceRequest = BaseParams & {
  pTransferNumber: string;
};

export type TransferAttendanceToQueueRequest = BaseParams & {
  pIdAttendGroup: number;
};

export type TransferAttendanceToUserRequest = BaseParams & {
  pIdUserTransferOmni: number;
};

export type TransferListAttendanceToQueueRequest = {
  pIdOmni: number[];
  pIdAttendGroup: number;
};

export type TransferListAttendanceToUserRequest = {
  pIdOmni: number[];
  pIdUserTransferOmni: number;
};

export type TransferVoiceRequest = BaseParams & {
  pIdOmni: string;
  pIdTransferType: number;
  pTransferNumber: string;
};

// Responses

export type AddAttendanceResponse = BaseReponseType & {
  idOmni: number;
};

export type AddAttendanceByDSResponse = BaseReponseType & {
  idOmni: number;
};

export type AddAttendanceByOmniResponse = BaseReponseType & {
  idOmni: number;
};

export type AllowAttendanceResponse = BaseReponseType;

export type AnswerQuestionResponse = BaseReponseType;

export type CancelTransferAssitedResponse = BaseReponseType;

export type ConfirmConferenceAttendanceResponse = BaseReponseType;

export type ConfirmTransferAssitedResponse = BaseReponseType;

export type CreateContactResponse = BaseReponseType & {
  idMailing: number;
};

export type CreateRoomResponse = BaseReponseType & {
  roomUniqueName: string;
  token1: string;
  token2: string;
};

export type DialAttendanceResponse = BaseReponseType;

export type DialAttendanceFromOtherMediaResponse = BaseReponseType;

export type DialConferenceAttendanceResponse = BaseReponseType;

export type FinishAttendanceResponse = BaseReponseType;

export type FinishConferenceAttendanceResponse = BaseReponseType;

export type GetAgentAPIsReponse = BaseReponseType & {
  deAgentAPI: string;
  deStatusAgentAPI: string;
  idReturnAgentAPI: number;
  isOnAcw: boolean;
  qtSecInACW: number;
};

export type GetAttendancesReponse = BaseReponseType & {
  omniOnLineList?: AttendanceModel[];
  isVerifyIfGetNextOmniActive: boolean;
  qtUserOmniRealtimeList: number;
};

export type GetIntegrationsResponse = BaseReponseType & {
  omniIntegrationDataList: IntegrationModel[];
};

export type GetFormFieldsResponse = BaseReponseType & {
  bindListCustomer: FormFieldModel[];
};

export type GetFormOrderResponse = BaseReponseType & {
  bindListOrder: FormFieldModel[];
};

export type GetFormTicketResponse = BaseReponseType & {
  bindListTicket: FormFieldModel[];
};

export type GetHistoriesResponse = BaseReponseType & {
  omniHistoryMessageList: HistoricModel[];
};

export type GetMessagesIdResponse = BaseReponseType & {
  omniMessageListOnLine: { IdOmniMessage: number; DeOmniMessage: string }[];
  qtOmniMessageListOnLine: number;
  deLastMessage: string;
  dtLastMessage: string;
  isChat: boolean;
};

export type GetMessagesResponse = BaseReponseType & {
  omniMessageListOnLine: MessageModel[];
  isChat: boolean;
};

export type GetMeQueuesResponse = BaseReponseType & {
  userOmniRealtimeList: UserQueuesModel[];
};

export type GetOrderListResponse = BaseReponseType & {
  orderList: OrderModel[];
};

export type GetQuestionResponse = BaseReponseType & QuestionModel;

export type GetQuizResponse = BaseReponseType;

export type GetTicketListResponse = BaseReponseType & {
  ticketList: AttendanceTicketModel[];
};

export type GetTokenByRoomResponse = BaseReponseType & {
  token: string;
  nmUser: string;
};

export type HangupAttendanceResponse = BaseReponseType;

export type ManageOrderItemResponse = BaseReponseType & {
  idOrder: number;
};

export type ManageTicketItemResponse = BaseReponseType & {
  idTicket: number;
};

export type PreviousQuestionResponse = BaseReponseType & QuestionModel;

export type RedialAttendanceResponse = BaseReponseType;

export type ScheduleAttendanceResponse = BaseReponseType;

export type SearchAttendanceResponse = BaseReponseType & {
  omniList: SearchOmniModel[];
};

export type SearchContactResponse = BaseReponseType & {
  mailingList: SearchMailingModel[];
};

export type SearchContactInDSResponse = BaseReponseType & {
  mailingList: SearchMailingModel[];
};

export type SendFileResponse = BaseReponseType;

export type SendFilesByTicketResponse = BaseReponseType;

export type SendHSMResponse = BaseReponseType;

export type SendHSMFromOtherMediaResponse = BaseReponseType;

export type SendMessageResponse = BaseReponseType;

export type StartNewRecordResponse = BaseReponseType;

export type StartNPSSearchReponse = BaseReponseType;

export type TransferAssistedAttendanceResponse = BaseReponseType;

export type TransferAttendanceToQueueResponse = BaseReponseType;

export type TransferAttendanceToUserResponse = BaseReponseType;

export type TransferListAttendanceToQueueResponse = BaseReponseType;

export type TransferListAttendanceToUserResponse = BaseReponseType;

export type TransferVoiceResponse = BaseReponseType;
