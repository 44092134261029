import {
  minDate,
  validAlphanumeric,
  validCNPJ,
  validCPF,
  validEmail,
  validPassword,
  validPhone,
} from "consts";
import { StepAttendanceEnum } from "modals/addAttendance/viewModel";
import { ControlTypeIDEnum, SubChannelTypeEnum } from "models";
import { timeIsSameOrAfter } from "utils";
import * as yup from "yup";
import { AnyObject } from "yup/lib/types";

const timeTest = (key: string) =>
  function (this: yup.TestContext<AnyObject>, end?: string) {
    const start = this.parent[key];
    const times = ["00:00", "00:00:00"];

    if (start) {
      if (times.includes(start) && end && times.includes(end)) return true;
      else if (end) return timeIsSameOrAfter(start, end);

      return false;
    }

    return true;
  };

// Defaults

const AlphanumericSchema = yup
  .string()
  .nullable()
  .transform((value, origin) => (origin === "" ? undefined : value))
  .matches(validAlphanumeric, "errors.invalidAlphanumeric");

const BooleanSchema = yup
  .boolean()
  .nullable()
  .transform((value) => value ?? false);

const BooleanRequiredSchema = BooleanSchema.required("errors.required");

const CpfCnpjSchema = yup.string().test((value, ctx) => {
  if (!!value && !validCPF.test(value) && !validCNPJ.test(value))
    return ctx.createError({
      message: "errors.invalidCpfCnpj",
      type: "invalid",
    });

  return true;
});

const DateSchema = yup
  .date()
  .nullable()
  .transform((value, origin) => (origin === "" ? undefined : value));

const DateMaxSchema = DateSchema.min(minDate, "errors.invalidDate").max(
  new Date(),
  "errors.invalidMaxDate"
);

const DateMinSchema = DateSchema.min(new Date(), "errors.invalidMinDate");

const DateRequiredSchema = DateSchema.required("errors.required");

const DateMaxRequiredSchema = DateMaxSchema.required("errors.required");

const DateMinRequiredSchema = DateMinSchema.required("errors.required");

const EmailSchema = yup
  .string()
  .nullable()
  .transform((value, origin) => (origin === "" ? undefined : value))
  .matches(validEmail, "errors.invalidEmail");

const EmailRequiredSchema = EmailSchema.required("errors.required");

const MixedSchema = yup.mixed();

const MixedRequiredSchema = MixedSchema.required("errors.required");

const NumberSchema = yup
  .number()
  .nullable()
  .transform((value) => (isNaN(value) ? undefined : value));

const NumberRequiredSchema = NumberSchema.required("errors.required");

const NumberArraySchema = yup.array().of(yup.number());

const NumberArrayRequiredSchema = NumberArraySchema.required(
  "errors.required"
).min(1, "errors.required");

const OptionalSchema = yup.string().optional();

const PasswordSchema = yup
  .string()
  .required("errors.required")
  .matches(validPassword, "errors.invalidPassword");

const PhoneSchema = yup.string().matches(validPhone, {
  excludeEmptyString: true,
  message: "errors.invalidPhone",
});

const PhoneRequiredSchema = PhoneSchema.required("errors.required");

const RequiredSchema = yup.string().required("errors.required");

const TimeSchema = yup.string();

const TimeRequiredSchema = TimeSchema.required("errors.required");

// Exports

export const AddAttendanceSchema = yup.object().shape({
  step: NumberRequiredSchema,
  name: OptionalSchema.when(
    ["step", "ddd", "phone", "cpfCnpj", "contract", "user"],
    {
      is: (
        step: StepAttendanceEnum,
        ddd: string,
        phone: string,
        cpfCnpj: string,
        contract: string,
        user: number
      ) =>
        step === StepAttendanceEnum.addContact ||
        (!ddd && !phone && !cpfCnpj && !contract && !user),
      then: RequiredSchema,
    }
  ),
  ddd: OptionalSchema,
  phone: OptionalSchema.when(["step", "ddd"], {
    is: (step: StepAttendanceEnum, ddd: string) =>
      step === StepAttendanceEnum.addContact || !!ddd,
    then: RequiredSchema,
  }),
  cpfCnpj: CpfCnpjSchema,
  contract: OptionalSchema,
  channel: NumberSchema.when("step", {
    is: (step: StepAttendanceEnum) =>
      step !== StepAttendanceEnum.searchAttendance,
    then: NumberRequiredSchema,
  }),
  user: NumberSchema,
  folder: NumberSchema,
  start: DateMaxSchema.when("step", {
    is: (step: StepAttendanceEnum) =>
      step === StepAttendanceEnum.searchAttendance,
    then: DateMaxRequiredSchema,
  }),
  end: DateMaxSchema.when("step", {
    is: (step: StepAttendanceEnum) =>
      step === StepAttendanceEnum.searchAttendance,
    then: DateMaxRequiredSchema.min(yup.ref("start"), "errors.invalidDate"),
  }),
});

export const BatchCampaignQueueSchema = yup.object().shape({});

export const BatchCampaignSchema = yup.object().shape({});

export const BatchQueueSchema = yup.object().shape({});

export const BatchUserQueueSchema = yup.object().shape({
  queues: NumberArrayRequiredSchema,
  action: NumberRequiredSchema,
  priority: NumberRequiredSchema,
});

export const BatchUserSchema = yup.object().shape({
  superior: NumberRequiredSchema,
  permission: NumberRequiredSchema,
  project: NumberRequiredSchema,
});

export const BatchTransferAttendanceSchema = yup.object().shape({
  step: NumberRequiredSchema,
  transferBy: RequiredSchema,
});

export const ConferenceAttendanceSchema = yup.object().shape({
  externalPhone: NumberRequiredSchema,
});

export const CreatePasswordSchema = yup.object().shape({
  password: PasswordSchema,
});

export const DialAttendanceSchema = yup.object().shape({
  ddd: OptionalSchema,
  phone: RequiredSchema,
});

export const EndAttendanceSchema = yup.object().shape({
  isVoice: BooleanRequiredSchema,
  folder: OptionalSchema.when("isVoice", {
    is: (isVoice: boolean) => !isVoice,
    then: RequiredSchema,
  }),
  statusGroup: RequiredSchema,
  status: RequiredSchema,
});

export const ForgotEmailSchema = yup.object().shape({
  phone: PhoneRequiredSchema,
});

export const ForgotPasswordSchema = yup.object().shape({
  email: EmailRequiredSchema,
});

export const HSMImportSchema = yup.object().shape({
  batchName: RequiredSchema,
  sendDate: DateMinRequiredSchema,
  template: NumberRequiredSchema,
  file: MixedRequiredSchema,
});

export const ImportSchema = yup.object().shape({
  name: RequiredSchema,
  type: NumberRequiredSchema,
  file: MixedRequiredSchema,
});

export const ManageCampaignSchema = yup.object().shape({
  name: RequiredSchema,
  queue: NumberSchema,
});

export const ManageCampaignTypeSchema = yup.object().shape({
  name: RequiredSchema,
  form: NumberRequiredSchema,
});

export const ManageChannelSchema = yup.object().shape({
  type: NumberRequiredSchema,
  campaignType: NumberRequiredSchema,
  name: RequiredSchema,
  phone: OptionalSchema.when("type", {
    is: (type: number) => type === SubChannelTypeEnum.WhatsApp,
    then: RequiredSchema,
  }),
  botId: OptionalSchema.when("type", {
    is: (type: number) => type !== SubChannelTypeEnum.WhatsApp,
    then: RequiredSchema,
  }),
});

export const ManageCompanySchema = yup.object().shape({});

export const ManageIntervalSchema = yup.object().shape({
  name: RequiredSchema,
  dsCode: NumberRequiredSchema,
});

export const ManageFAQSchema = yup.object().shape({
  // channel: NumberRequiredSchema.min(1, "errors.required"),
  question: RequiredSchema,
  response: RequiredSchema,
});

export const ManageFieldSchema = yup
  .object()
  .shape({
    template: NumberRequiredSchema,
    name: RequiredSchema,
    context: NumberRequiredSchema,
    controlType: NumberRequiredSchema,
    columnName: RequiredSchema,
    type: NumberRequiredSchema,
    maxLength: NumberSchema.when("controlType", {
      is: (controlType: number) => controlType === ControlTypeIDEnum.TextBox,
      then: NumberRequiredSchema.min(1, "errors.invalidMinNumber").max(
        500,
        "errors.invalidMaxNumber"
      ),
    }),
    controlSize: NumberRequiredSchema,
    fieldOrder: NumberRequiredSchema,
  })
  .test(({ regularExpression, messageRegex }, ctx) => {
    if (!!regularExpression && !messageRegex)
      return new yup.ValidationError(
        "errors.required",
        messageRegex,
        "messageRegex"
      );
    else if (!!messageRegex && !regularExpression)
      return new yup.ValidationError(
        "errors.required",
        regularExpression,
        "regularExpression"
      );
    else return true;
  });

export const ManageFormSchema = yup.object().shape({
  name: RequiredSchema,
});

export const ManageHSMTemplateSchema = yup.object().shape({
  name: RequiredSchema,
  code: RequiredSchema,
  channel: NumberRequiredSchema,
  phrase: RequiredSchema,
});

export const ManageProductSchema = yup.object().shape({
  name: RequiredSchema,
});

export const ManageProjectSchema = yup.object().shape({
  name: RequiredSchema,
});

export const ManageQueueSchema = yup.object().shape({
  name: RequiredSchema,
  channelType: NumberRequiredSchema,
  omniSource: NumberRequiredSchema,
  max: NumberRequiredSchema.min(0, "errors.required"),
  start: TimeRequiredSchema,
  end: TimeRequiredSchema.test(
    "is-greater",
    "errors.invalidDate",
    timeTest("start")
  ),
  startSaturday: TimeSchema,
  endSaturday: TimeSchema.test(
    "is-greater",
    "errors.invalidDate",
    timeTest("startSaturday")
  ),
  startSunday: TimeSchema,
  endSunday: TimeSchema.test(
    "is-greater",
    "errors.invalidDate",
    timeTest("startSunday")
  ),
});

export const ManageStatusGroupSchema = yup.object().shape({
  name: RequiredSchema,
});

export const ManageStatusSchema = yup.object().shape({
  statusGroup: NumberRequiredSchema,
  name: RequiredSchema,
  code: RequiredSchema,
  dialerCode: RequiredSchema,
  type: NumberRequiredSchema,
});

export const ManageUserProjectSchema = yup.object().shape({
  projects: NumberArrayRequiredSchema,
});

export const ManageUserSchema = yup
  .object()
  .shape({
    name: RequiredSchema,
    accessLogin: RequiredSchema,
    phoneLogin: AlphanumericSchema,
    email: EmailSchema,
    registrationNumber: OptionalSchema,
    phone: PhoneSchema,
    birth: DateMaxSchema,
    project: NumberRequiredSchema,
    superior: NumberRequiredSchema,
    permission: NumberRequiredSchema,
  })
  .test(({ ddd, phone }, ctx) => {
    if (!!ddd && !phone)
      return new yup.ValidationError("errors.required", phone, "phone");
    else if (!!phone && !ddd)
      return new yup.ValidationError("errors.required", ddd, "ddd");
    else return true;
  });

export const MergeAttendanceSchema = yup.object().shape({});

export const NewPasswordSchema = yup.object().shape({
  password: RequiredSchema,
  newPassword: PasswordSchema,
  confirmPassword: RequiredSchema.oneOf(
    [yup.ref("newPassword"), null],
    "errors.passwordDoesNotMatch"
  ),
});

export const RedialAttendanceSchema = yup.object().shape({
  status: NumberRequiredSchema,
  ddd: OptionalSchema,
  phone: RequiredSchema,
});

export const ScheduleAttendanceSchema = yup.object().shape({
  status: RequiredSchema,
  date: DateRequiredSchema,
  hour: TimeRequiredSchema,
  ddd: RequiredSchema,
  phone: PhoneRequiredSchema,
});

export const SelectProjectSchema = yup.object().shape({
  project: NumberRequiredSchema,
});

export const SendHSMSchema = yup.object().shape({
  template: RequiredSchema,
});

export const SendHSMTestSchema = yup.object().shape({
  phone: PhoneRequiredSchema,
});

export const SignInSchema = yup.object().shape({
  login: RequiredSchema,
  password: RequiredSchema,
});

export const SignUpSchema = yup.object().shape({
  companyName: RequiredSchema,
  name: RequiredSchema,
  phone: PhoneSchema,
  email: EmailRequiredSchema,
  password: PasswordSchema,
});

export const TransferAssistedAttendanceSchema = yup.object().shape({
  transferBy: OptionalSchema,
  branch: RequiredSchema,
});

export const TransferAttendanceSchema = yup.object().shape({
  isVoice: BooleanRequiredSchema,
  step: NumberRequiredSchema,
  transferBy: OptionalSchema.when("isVoice", {
    is: (isVoice: boolean) => !isVoice,
    then: RequiredSchema,
  }),
  branch: OptionalSchema.when("isVoice", {
    is: (isVoice: boolean) => isVoice,
    then: RequiredSchema,
  }),
});

export const UserImportSchema = yup.object().shape({
  project: NumberRequiredSchema,
  file: MixedRequiredSchema,
});

export const WelcomeSchema = yup.object().shape({
  company: RequiredSchema,
  organization: RequiredSchema,
});
